import { Controller } from '@hotwired/stimulus';
import { MDCDialog } from '@material/dialog';

export default class extends Controller {
  static outlets = ['mdc--button'];

  connect() {
    console.log(this.element);
    this.dialog = new MDCDialog(this.element);
    this.addAccessibilityListeners();
  }

  disconnect() {
    if (this.dialog) {
      this.dialog.destroy();
    }
  }

  mdcButtonOutletConnected(_, buttonElement) {
    buttonElement.addEventListener('click', () => {
      this.openDialog();
    });
  }

  openDialog() {
    this.dialog.open();
  }

  addAccessibilityListeners() {
    this.dialog.listen('MDCDialog:opened', () => {
      document.querySelector('body').setAttribute('aria-hidden', 'true');
    });
    this.dialog.listen('MDCDialog:closing', () => {
      document.querySelector('body').removeAttribute('aria-hidden');
    });
  }
}
