window.invalid_text = 'Invalid combination, try again.';
window.hiv_risk_data = {
  Butt: {
    Hand: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0.0,
              Explain:
                'Getting your butt fingered poses a VERY LOW risk for HIV.'
            }
          }
        }
      }
    },
    Mouth: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0.0,
              Explain:
                'Getting a rim job (oral sex on butt) poses a VERY LOW risk for HIV.'
            }
          }
        }
      }
    },
    Penis: {
      Condom: {
        Null: {
          Null: {
            Null: {
              Value: 39.0,
              Explain:
                'You’re the BOTTOM partner during ANAL sex, your partner has a detectable viral load, and you use condoms. Good job using condoms! But, your partner’s detectable viral load can still put you at risk for HIV. So, use more than one form of protection that IS under your control.  Add or subtract some factors to see how your HIV risk changes.'
            },
            Sti: {
              Value: 102.0,
              Explain:
                'You’re the BOTTOM partner during ANAL sex, one of you has an STI, your partner has a detectable viral load, and you use condoms. Good job using condoms! However, this still poses a HIGH risk for HIV, because your partner’s detectable viral load plus certain STIs can increase HIV risk. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes.'
            }
          },
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                "You’re the BOTTOM partner during ANAL sex, your partner is undetectable, and you use condoms. Great job! Using condoms and having a partner who is undetectable reduces your risk of HIV by a lot! But, you can't control your partner's viral load. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes."
            },
            Sti: {
              Value: 0.5,
              Explain:
                'You’re the BOTTOM partner during ANAL sex, one of you has an STI, your partner is undetectable, and you use condoms. Using condoms and having a partner who is undetectable reduces your risk of HIV by a lot. Great job! However, you can’t control your partner’s viral load and certain STIs can increase HIV risk. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes.'
            }
          }
        },
        Prep: {
          Null: {
            Null: {
              Value: 0.5,
              Explain:
                'You’re the BOTTOM partner during ANAL sex, your partner has a detectable viral load, you take PrEP, and you use condoms. Great job! Using two layers of protection reduces your risk of HIV by a lot! Add or subtract some factors to see how your HIV risk changes.'
            },
            Sti: {
              Value: 1.0,
              Explain:
                'You’re the BOTTOM partner during ANAL sex, one of you has an STI, your partner has a detectable viral load, you take PrEP, and you use condoms. Great job! Using two layers of protection reduces your risk of HIV by a lot! However, certain STIs can increase HIV risk. Add or subtract some factors to see how your HIV risk changes.'
            }
          },
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                'You’re the BOTTOM partner during ANAL sex, your partner is undetectable, you take PrEP, and you use condoms. This poses a LOW risk for HIV. Great job! You’re doing everything you can to protect your sexual health! Add or subtract some factors to see how your HIV risk changes.'
            },
            Sti: {
              Value: 0.5,
              Explain:
                'You’re the BOTTOM partner during ANAL sex, one of you has an STI, your partner is undetectable, you take PrEP, and you use condoms.  This poses a LOW risk for HIV. Great job! You’re doing a lot to protect your sexual health! However, certain STIs can increase HIV risk. Subtract some factors to see how your HIV risk changes.'
            }
          }
        }
      },
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 138.0,
              Explain:
                'You’re the BOTTOM partner during ANAL sex, your partner has a detectable viral load, and you don’t use protection. This poses a HIGH risk for HIV because it is easier for HIV to enter the body and infect you if you’re not using protection, and your partner’s viral load can increase your HIV risk. So, use more than one form of protection that IS under your control. Add some factors to see how your HIV risk changes.'
            },
            Sti: {
              Value: 366.0,
              Explain:
                'You’re the BOTTOM partner during ANAL sex, one of you has an STI, your partner has a detectable viral load, and you don’t use protection.  This poses one of the HIGHEST risks for HIV because it is easier for HIV to enter the body and infect you if you’re not using protection, and your partner’s detectable viral load plus certain STIs can increase your HIV risk. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes.'
            }
          },
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                'You’re the BOTTOM partner during ANAL sex, and your partner is undetectable. The medication your HIV-positive partner takes helps keep HIV from spreading. But, you can’t control your partner’s viral load. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes.'
            },
            Sti: {
              Value: 0.5,
              Explain:
                "You’re the BOTTOM partner during ANAL sex, one of you has an STI, and your partner is undetectable. This puts you at risk for HIV. The medication your HIV-positive partner takes helps keep HIV from spreading. But, you can't control your partner's viral load, and certain STIs can increase HIV risk. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes."
            }
          }
        },
        Prep: {
          Null: {
            Null: {
              Value: 1.0,
              Explain:
                'You’re the BOTTOM partner during ANAL sex, your partner has a detectable viral load, and you take PrEP. Good job taking PrEP! But, your partner’s detectable viral load can still put you at risk for HIV. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes.'
            },
            Sti: {
              Value: 4.0,
              Explain:
                'You’re the BOTTOM partner during ANAL sex, one of you has an STI, your partner has a detectable viral load, and you take PrEP. Good job taking PrEP! However, your partner’s detectable viral load plus certain STIs can still put you at risk for HIV. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes.'
            }
          },
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                "You’re the BOTTOM partner during ANAL sex, your partner is undetectable, and you take PrEP. This poses a LOW risk for HIV. Great job! Taking PrEP and having a partner who is undetectable reduces your risk of HIV by a lot! But, you can't control your partner's viral load. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes."
            },
            Sti: {
              Value: 0.5,
              Explain:
                'You’re the BOTTOM partner during ANAL sex, one of you has an STI, your partner is undetectable, and you take PrEP. Taking PrEP and having a partner who is undetectable reduces your risk of HIV by a lot. Great job! However, you can’t control your partner’s viral load, and certain STIs can increase HIV risk. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes.'
            }
          }
        }
      }
    }
  },
  Hand: {
    Butt: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0.0,
              Explain:
                "Fingering your partner's butt poses a VERY LOW risk for HIV."
            }
          }
        }
      }
    },
    Hand: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0.0,
              Explain:
                'Touching, like holding hands and cuddling, does not put you at risk for HIV. Try another combination!'
            }
          }
        }
      }
    },
    Penis: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0.0,
              Explain:
                'Giving your partner a hand job poses a VERY LOW risk for HIV.'
            }
          }
        }
      }
    },
    Vagina: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0.0,
              Explain:
                "Fingering your partner's vagina poses a VERY LOW risk for HIV."
            }
          }
        }
      }
    }
  },
  Mouth: {
    Butt: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0.0,
              Explain:
                'Giving your partner a rim job (oral sex on butt) poses a VERY LOW risk for HIV.'
            }
          }
        }
      }
    },
    Mouth: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0.0,
              Explain:
                'Kissing does not put you at risk for HIV. Try another combination!'
            }
          }
        }
      }
    },
    Penis: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0.0,
              Explain:
                'Giving your partner a blowjob (oral sex on penis) poses a VERY LOW risk for HIV.'
            }
          }
        }
      }
    },
    Vagina: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0.0,
              Explain:
                'Going down (oral sex) on a vagina poses a VERY LOW risk for HIV.'
            }
          }
        }
      }
    }
  },
  Penis: {
    Butt: {
      Condom: {
        Null: {
          Null: {
            Null: {
              Value: 4.0,
              Explain:
                'You’re the TOP partner during ANAL sex, your partner has a detectable viral load, and you use condoms. Good job using condoms! But your partner’s detectable viral load can still put you at risk for HIV. So, use more than one form of protection that IS under your control.  Add or subtract some factors to see how your HIV risk changes.'
            },
            Sti: {
              Value: 11.0,
              Explain:
                'You’re the TOP partner during ANAL sex, one of you has an STI, your partner has a detectable viral load, and you use condoms. Good job using condoms! However, you can’t control your partner’s viral load and certain STIs can increase HIV risk. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes.'
            }
          },
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during ANAL sex, your partner is undetectable, and you use condoms. This poses a LOW risk for HIV. Great job! Using condoms and having a partner who is undetectable reduces your risk of HIV by a lot! But, you can't control your partner's viral load. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes."
            },
            Sti: {
              Value: 0.5,
              Explain:
                'You’re the TOP partner during ANAL sex, one of you has an STI, your partner is undetectable, and you use condoms. This poses a LOW risk for HIV. Using condoms and having a partner who is undetectable reduces your risk of HIV by a lot. Great job! However, you can’t control your partner’s viral load, and certain STIs can increase HIV risk. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes.'
            }
          }
        },
        Prep: {
          Null: {
            Null: {
              Value: 0.5,
              Explain:
                'You’re the TOP partner during ANAL sex, your partner has a detectable viral load, you take PrEP, and you use condoms. This poses a LOW risk for HIV. Great job! Using two layers of protection reduces your risk of HIV by a lot! Add or subtract some factors to see how your HIV risk changes.'
            },
            Sti: {
              Value: 0.5,
              Explain:
                'You’re the TOP partner during ANAL sex, one of you has an STI, your partner has a detectable viral load, you take PrEP, and you use condoms. Great job! Using two layers of protection reduces your risk of HIV by a lot! However, certain STIs can increase HIV risk. Add or subtract some factors to see how your HIV risk changes.'
            }
          },
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                'You’re the TOP partner during ANAL sex, your partner is undetectable, you take PrEP, and you use condoms. This poses a LOW risk for HIV. Great job! You’re doing everything you can to protect your sexual health! Add or subtract some factors to see how your HIV risk changes.'
            },
            Sti: {
              Value: 0.5,
              Explain:
                'You’re the TOP partner during ANAL sex, one of you has an STI, your partner is undetectable, you take PrEP, and you use condoms. This poses a LOW risk for HIV. Great job! You’re doing a lot to protect your sexual health! However, certain STIs can increase HIV risk. Subtract some factors to see how your HIV risk changes.'
            }
          }
        }
      },
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 11.0,
              Explain:
                'You’re the TOP partner during ANAL sex, your partner has a detectable viral load, and you don’t use protection. Not using protection puts you at risk for HIV because it is easier for HIV to enter the body and infect you, and your partner’s viral load can increase your HIV risk. So, use more than one form of protection that IS under your control. Add some factors to see how your HIV risk changes.'
            },
            Sti: {
              Value: 29.0,
              Explain:
                'You’re the TOP partner during ANAL sex, one of you has an STI, your partner has a detectable viral load, and you don’t use protection. Not using protection puts you at risk for HIV because it is easier for HIV to enter the body and infect you, and your partner’s detectable viral load plus certain STIs that can increase your HIV risk. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes.'
            }
          },
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                'You’re the TOP partner during ANAL sex, and your partner is undetectable. This poses a LOW risk for HIV. The medication your HIV-positive partner takes helps keep HIV from spreading. But, you can’t control your partner’s viral load. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes.'
            },
            Sti: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during ANAL sex, one of you has an STI, and your partner is undetectable. The medication your HIV-positive partner takes helps keep HIV from spreading. But, you can't control your partner's viral load, and certain STIs can increase HIV risk. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes."
            }
          }
        },
        Prep: {
          Null: {
            Null: {
              Value: 0.5,
              Explain:
                'You’re the TOP partner during ANAL sex, your partner has a detectable viral load, and you take PrEP. Good job taking PrEP! But, your partner’s detectable viral load can still put you at risk for HIV. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes.'
            },
            Sti: {
              Value: 0.5,
              Explain:
                'You’re the TOP partner during ANAL sex, one of you has an STI, your partner has a detectable viral load, and you take PrEP. Good job taking PrEP! However, your partner’s detectable viral load plus certain STIs can still put you at risk for HIV. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes.'
            }
          },
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during ANAL sex, your partner is undetectable, and you take PrEP. This poses a LOW risk for HIV. Great job! Taking PrEP and having a partner who is undetectable reduces your risk of HIV by a lot! But, you can't control your partner's viral load. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes."
            },
            Sti: {
              Value: 0.5,
              Explain:
                'You’re the TOP partner during ANAL sex, one of you has an STI, your partner is undetectable, and you take PrEP. This poses a LOW risk for HIV. Taking PrEP and having a partner who is undetectable reduces your risk of HIV by a lot. Great job! However, you can’t control your partner’s viral load, and certain STIs can increase HIV risk. So, use more than one form of protection that IS under your control.  Add or subtract some factors to see how your HIV risk changes.'
            }
          }
        }
      }
    },
    Hand: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0.0,
              Explain: 'Getting a hand job poses a VERY LOW risk for HIV.'
            }
          }
        }
      }
    },
    Mouth: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0.0,
              Explain:
                'Getting a blowjob (oral sex on penis) poses a VERY LOW risk for HIV.'
            }
          }
        }
      }
    },
    Penis: {
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 0.0,
              Explain:
                "Grinding, or rubbing your penis and your partner's penis together, poses a VERY LOW risk for HIV."
            }
          }
        }
      }
    },
    Vagina: {
      Condom: {
        Null: {
          Null: {
            Null: {
              Value: 1.0,
              Explain:
                'You’re the TOP partner during VAGINAL sex, your partner has a detectable viral load, and you use condoms. Good job using condoms! But, your partner’s detectable viral load can still put you at risk for HIV. So, use more than one form of protection that IS under your control.  Add or subtract some factors to see how your HIV risk changes.'
            },
            Sti: {
              Value: 2.0,
              Explain:
                'You’re the TOP partner during VAGINAL sex, one of you has an STI, your partner has a detectable viral load, and you use condoms. Good job using condoms! However, your partner’s detectable viral load plus certain STIs can still put you at risk for HIV. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes.'
            }
          },
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during VAGINAL sex, your partner is undetectable, and you use condoms. This poses a LOW risk for HIV. Great job! Using condoms and having a partner who is undetectable reduces your risk of HIV by a lot! But, you can't control your partner's viral load. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes."
            },
            Sti: {
              Value: 0.5,
              Explain:
                'You’re the TOP partner during VAGINAL sex, one of you has an STI, your partner is undetectable, and you use condoms. This poses a LOW risk for HIV. Using condoms and having a partner who is undetectable reduces your risk of HIV by a lot. Great job! However, you can’t control your partner’s viral load, and certain STIs can increase HIV risk. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes.'
            }
          }
        },
        Prep: {
          Null: {
            Null: {
              Value: 0.5,
              Explain:
                'You’re the TOP partner during VAGINAL sex, your partner has a detectable viral load, you take PrEP, and you use condoms. This poses a LOW risk for HIV. Great job! Using two layers of protection reduces your risk of HIV by a lot! Add or subtract some factors to see how your HIV risk changes.'
            },
            Sti: {
              Value: 0.5,
              Explain:
                'You’re the TOP partner during VAGINAL sex, one of you has an STI, your partner has a detectable viral load, you take PrEP, and you use condoms. This poses a LOW risk for HIV. Great job! Using two layers of protection reduces your risk of HIV by a lot! However, certain STIs can increase HIV risk. Add or subtract some factors to see how your HIV risk changes.'
            }
          },
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                'You’re the TOP partner during VAGINAL sex, your partner is undetectable, you take PrEP, and you use condoms. This poses a LOW risk for HIV. Great job! You’re doing everything you can to protect your sexual health! Add or subtract some factors to see how your HIV risk changes.'
            },
            Sti: {
              Value: 0.5,
              Explain:
                'You’re the TOP partner during VAGINAL sex, one of you has an STI, your partner is undetectable, you take PrEP, and you use condoms.  This poses a LOW risk for HIV. Great job! You’re doing a lot to protect your sexual health! However, certain STIs can increase HIV risk. Subtract some factors to see how your HIV risk changes.'
            }
          }
        }
      },
      Null: {
        Null: {
          Null: {
            Null: {
              Value: 4.0,
              Explain:
                'You’re the TOP partner during VAGINAL sex, your partner has a detectable viral load, and you don’t use protection. Not using protection puts you at risk for HIV because it is easier for HIV to enter the body and infect you, and your partner’s viral load can increase your HIV risk. So, use more than one form of protection that IS under your control. Add some factors to see how your HIV risk changes.'
            },
            Sti: {
              Value: 11.0,
              Explain:
                'You’re the TOP partner during VAGINAL sex, one of you has an STI, your partner has a detectable viral load, and you don’t use protection. Not using protection puts you at risk for HIV because it is easier for HIV to enter the body and infect you, and your partner’s detectable viral load plus certain STIs can increase your HIV risk. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes.'
            }
          },
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                'You’re the TOP partner during VAGINAL sex, and your partner is undetectable. This poses a LOW risk for HIV.  The medication your HIV-positive partner takes helps keep HIV from spreading. But, you can’t control your partner’s viral load. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes.'
            },
            Sti: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during VAGINAL sex, one of you has an STI, and your partner is undetectable. This poses a LOW risk for HIV. The medication your HIV-positive partner takes helps keep HIV from spreading. But, you can't control your partner's viral load, and certain STIs can increase HIV risk. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes."
            }
          }
        },
        Prep: {
          Null: {
            Null: {
              Value: 0.5,
              Explain:
                'You’re the TOP partner during VAGINAL sex, your partner has a detectable viral load, and you take PrEP. This poses a LOW risk for HIV. Good job taking PrEP! But, your partner’s detectable viral load can still put you at risk for HIV. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes.'
            },
            Sti: {
              Value: 0.5,
              Explain:
                'You’re the TOP partner during VAGINAL sex, one of you has an STI, your partner has a detectable viral load, and you take PrEP. Good job taking PrEP! However, your partner’s detectable viral load plus certain STIs can still put you at risk for HIV. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes.'
            }
          },
          Uplus: {
            Null: {
              Value: 0.5,
              Explain:
                "You’re the TOP partner during VAGINAL sex, your partner is undetectable, and you take PrEP. This poses a LOW risk for HIV. Great job! Taking PrEP and having a partner who is undetectable reduces your risk of HIV by a lot! But, you can't control your partner's viral load, So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes."
            },
            Sti: {
              Value: 0.5,
              Explain:
                'You’re the TOP partner during VAGINAL sex, one of you has an STI, your partner is undetectable, and you take PrEP. This poses a LOW risk for HIV. Taking PrEP and having a partner who is undetectable reduces your risk of HIV by a lot. Great job! However, you can’t control your partner’s viral load, and certain STIs can increase HIV risk. So, use more than one form of protection that IS under your control. Add or subtract some factors to see how your HIV risk changes.'
            }
          }
        }
      }
    }
  }
};
