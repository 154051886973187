import { Controller } from '@hotwired/stimulus';
import { MDCList } from '@material/list';
import { MDCRipple } from '@material/ripple';

export default class extends Controller {
  connect() {
    this.list = new MDCList(this.element);
    this.ripples = [];
    this.list.listElements.forEach((listElement) => {
      this.initializeRipple(listElement);
    });
  }

  disconnect() {
    if (this.list) {
      this.list.destroy();
    }

    this.ripples.forEach((ripple) => {
      ripple.destroy();
    });
  }

  initializeRipple(element) {
    this.ripples.push(new MDCRipple(element));
  }
}
