import Rails from '@rails/ujs';
import * as Sentry from '@sentry/browser';
import $ from 'jquery';

document.addEventListener('DOMContentLoaded', function (event) {
  if ($('#hivCalc').length) {
    var you, partner;
    you = partner = 'Null';
    var factors = {
      Condom: 'Null',
      Prep: 'Null',
      Uplus: 'Null',
      Sti: 'Null'
    };
    var xtrmSize = 65;
    var regSize = 35;
    var limit = 15;
    var max = 366;
    var invisibleArrow = {
      opacity: '0',
      left: '50%',
      right: '50%'
    };

    function sliderCSS(opacity, leftPos) {
      return {
        opacity: opacity,
        left: 'calc(' + leftPos + '% - .25em)'
      };
    }

    function arrowCSS(leftPos, rightPos, smaller) {
      return {
        opacity: '.75',
        left: 'calc(' + leftPos + ' + .25em' + (smaller ? ' + 1em' : '') + ')',
        right:
          'calc(100% - (' +
          rightPos +
          ' + .25em)' +
          (smaller ? '' : ' + 1em') +
          ')'
      };
    }

    $('.youBtn').click(function () {
      $(this).parent().siblings().children('.youBtn').removeClass('clicked');
      $(this).addClass('clicked');
      you = $(this).data('label');
      setSlider();
      sendSelection('you_' + $(this).data('label'));
    });

    $('.partnerBtn').click(function () {
      $(this)
        .parent()
        .siblings()
        .children('.partnerBtn')
        .removeClass('clicked');
      $(this).addClass('clicked');
      partner = $(this).data('label');
      setSlider();
      sendSelection('partner_' + $(this).data('label'));
    });

    $('.factorBtn').click(function () {
      if ($(this).hasClass('clicked')) {
        $(this).removeClass('clicked');

        if (!$('.factorBtn.clicked').length) {
          $('#factorSlider').css(sliderCSS(0, 0));
        }

        factors[$(this).data('label')] = 'Null';
      } else {
        $(this).addClass('clicked');
        factors[$(this).data('label')] = $(this).data('label');
      }

      setSlider();
      sendSelection($(this).data('label'));
    });

    $('.sexBtn').click(function () {
      $('.sexBtn.clicked').removeClass('clicked');
      $(this).addClass('clicked');

      if ($('#male').hasClass('clicked')) {
        $('#sex img')
          .attr({
            src: $('#male').data('img-path')
          })
          .parent()
          .data('label', 'Penis');
      } else {
        $('#sex img')
          .attr({
            src: $('#female').data('img-path')
          })
          .parent()
          .data('label', 'Vagina');
      }

      if ($('#sex').hasClass('clicked')) {
        partner = $('#sex').data('label');
        setSlider();
      }

      sendSelection($(this).data('label'));
    });

    function setSlider() {
      //returns undefined if any items are undefined
      var factorData = ((((((hiv_risk_data || {})[you] || {})[partner] || {})[
        factors['Condom']
      ] || {})[factors['Prep']] || {})[factors['Uplus']] || {})[factors['Sti']];
      var partnerData = ((((((hiv_risk_data || {})[you] || {})[partner] || {})[
        'Null'
      ] || {})['Null'] || {})['Null'] || {})['Null'];

      if (
        typeof factorData === 'undefined' &&
        typeof partnerData === 'undefined'
      ) {
        $('.bars').removeClass('fullbar');
        $('#factorSlider, #partnerSlider').css(sliderCSS(0, 0));
        $('#arrow').css(invisibleArrow);

        $('#explanation').text(invalid_text);
      } else if (typeof factorData === 'undefined') {
        $('#factorSlider').css(sliderCSS(0));
        $('#arrow').css(invisibleArrow);
        $('#explanation').text(partnerData['Explain']);

        partnerData['Value'] < limit
          ? $('.bars').removeClass('fullbar')
          : $('.bars').addClass('fullbar');

        var pDataVal = $('.bars').hasClass('fullbar')
          ? (partnerData['Value'] / max) * xtrmSize + regSize
          : (partnerData['Value'] / limit) * 100;
        $('#partnerSlider').css(sliderCSS(0.75, pDataVal));
      } else {
        $('#explanation').text(factorData['Explain']);

        factorData['Value'] >= limit || partnerData['Value'] >= limit
          ? $('.bars').addClass('fullbar')
          : $('.bars').removeClass('fullbar');

        var pDataVal = $('.bars').hasClass('fullbar')
          ? partnerData['Value'] < limit
            ? (partnerData['Value'] / limit) * regSize
            : (partnerData['Value'] / max) * xtrmSize + regSize
          : (partnerData['Value'] / limit) * 100;
        $('#partnerSlider').css(sliderCSS(0.75, pDataVal));

        if ($('.factorBtn.clicked').length) {
          var fDataVal = $('.bars').hasClass('fullbar')
            ? factorData['Value'] < limit
              ? (factorData['Value'] / limit) * regSize
              : (factorData['Value'] / max) * xtrmSize + regSize
            : (factorData['Value'] / limit) * 100;
          $('#factorSlider').css(sliderCSS(0.75, fDataVal));
          arrowCalc(fDataVal, pDataVal);
        } else {
          $('#arrow').css(invisibleArrow);
        }
      }

      $('#explanation').html(
        $('#explanation').text().replace(/.+?\./, '<b>$&</b><br>')
      );
    }

    function arrowCalc(fDataVal, pDataVal) {
      var factorPos = $('#factorSlider')[0].style.left;
      var partnerPos = $('#partnerSlider')[0].style.left;

      if (fDataVal < pDataVal) {
        $('#arrow').removeClass('right').addClass('left');
        $('#arrow').css(arrowCSS(factorPos, partnerPos, 1));
      } else {
        $('#arrow').removeClass('left').addClass('right');
        $('#arrow').css(arrowCSS(partnerPos, factorPos, 0));
      }
    }

    function sendSelection(item) {
      $.ajax({
        url: $('#hivCalc').data('api-url'),
        type: 'POST',
        data: { hiv_risk_calculator_selection: { item: item } },
        beforeSend: function (xhr) {
          Rails.CSRFProtection(xhr);
        }
      }).fail(function (error) {
        Sentry.captureException(error);
      });
    }
  }
});
