import { Controller } from '@hotwired/stimulus';
import { MDCDataTable } from '@material/data-table';

export default class extends Controller {
  connect() {
    this.dataTable = new MDCDataTable(this.element);
  }

  disconnect() {
    if (this.dataTable) {
      this.dataTable.destroy();
    }
  }

  sort(event) {
    window.location = event.detail.headerCell.dataset.url;
  }
}
