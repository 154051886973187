import { Controller } from '@hotwired/stimulus';
import { MDCFormField } from '@material/form-field';
import { MDCCheckbox } from '@material/checkbox';
import { MDCRadio } from '@material/radio';

export default class extends Controller {
  static targets = ['checkbox', 'radio'];

  checkboxTargetConnected() {
    this.initializeFormField();
    this.checkbox = new MDCCheckbox(this.checkboxTarget);
    this.formField.input = this.checkbox;
    this.checkboxTarget.addEventListener('change', () => {
      this.applyCheckboxStyles();
    });
    this.applyCheckboxStyles();
  }

  checkboxTargetDisconnected() {
    if (this.checkbox) {
      this.formField.input = null;
      this.checkbox.destroy();
      this.formField.destroy();
    }
  }

  radioTargetConnected() {
    this.initializeFormField();
    this.radio = new MDCRadio(this.radioTarget);
    this.formField.input = this.radio;
    this.radioTarget.addEventListener('change', () => {
      this.applyRadioStyles();
    });
    this.applyRadioStyles();
  }

  radioTargetDisconnected() {
    if (this.radio) {
      this.formField.input = null;
      this.radio.destroy();
      this.formField.destroy();
    }
  }

  initializeFormField() {
    this.formField = new MDCFormField(this.element);
  }

  applyCheckboxStyles() {
    let checkboxInput = this.checkboxTarget.querySelector('input');
    if (checkboxInput.checked) {
      this.element.classList.add('selected');
    } else {
      this.element.classList.remove('selected');
    }
  }

  applyRadioStyles() {
    let radioInput = this.radioTarget.querySelector('input');
    document
      .querySelectorAll('input[type="radio"][name="' + radioInput.name + '"]')
      .forEach((radio) => {
        let formField = radio.closest('.mdc-form-field');
        if (!radio.checked && formField != this.element) {
          formField.classList.remove('selected');
        }
      });
    if (radioInput.checked) {
      this.element.classList.add('selected');
    }
  }
}
