import { Controller } from '@hotwired/stimulus';

const MAX_CHECKED = 2;

export default class extends Controller {
  connect() {
    this.listenForRadioChanges();
    this.listenForCheckboxChanges();
  }

  checkboxes() {
    return this.element.querySelectorAll('input[type="checkbox"]');
  }

  checkedCheckboxes() {
    return this.element.querySelectorAll('input[type="checkbox"]:checked');
  }

  uncheckedCheckboxes() {
    return this.element.querySelectorAll(
      'input[type="checkbox"]:not(:checked)'
    );
  }

  radioButtons() {
    return this.element.querySelectorAll('input[type="radio"]');
  }

  listenForCheckboxChanges() {
    this.checkboxes().forEach((checkbox) => {
      checkbox.addEventListener('change', () => {
        this.limitEnabledCheckboxes();
      });
    });
  }

  limitEnabledCheckboxes() {
    let count = this.checkedCheckboxes().length;
    if (count >= MAX_CHECKED) {
      this.uncheckedCheckboxes().forEach((checkbox) => {
        checkbox.disabled = true;
      });
    } else {
      this.checkboxes().forEach((checkbox) => {
        checkbox.disabled = false;
      });
    }
  }

  listenForRadioChanges() {
    this.radioButtons().forEach((button) => {
      this.unrequireRadioTextBoxes(button);
      button.addEventListener('change', () => {
        this.unrequireRadioTextBoxes(button);
        let fieldElement = button.closest('.mdc-form-field');
        if (fieldElement.classList.contains('radio-with-text')) {
          let text = fieldElement.querySelector('input[type="text"]');
          text.required = true;
          text.closest('label').style.display = null;
          let formField = text.closest('.mdc-form-field');
          let otherLabel = formField.querySelector('label.other');
          otherLabel.style.display = 'none';
        }
      });
    });
  }

  unrequireRadioTextBoxes(radioButton) {
    radioButton
      .closest('.barriers-facilitators')
      .querySelectorAll('.radio-with-text input[type="text"]')
      .forEach((text) => {
        text.required = false;
        text.closest('label').style.display = 'none';
        let formField = text.closest('.mdc-form-field');
        let radio = formField.querySelector('input[type="radio"]');
        let otherLabel = formField.querySelector('label.other');

        if (otherLabel) {
          otherLabel.style.display = null;
        } else {
          otherLabel = document.createElement('label');
          otherLabel.classList.add('other');
          otherLabel.setAttribute('for', radio.id);
          otherLabel.append('Other');
          formField.append(otherLabel);
        }
      });
  }
}
