import { Controller } from '@hotwired/stimulus';
import { MDCLinearProgress } from '@material/linear-progress';

export default class extends Controller {
  connect() {
    this.progress = new MDCLinearProgress(this.element);
    this.progress.progress = parseFloat(
      this.element.attributes['aria-valuenow'].value
    );
  }

  disconnect() {
    if (this.progress) {
      this.progress.destroy();
    }
  }
}
