import { Controller } from '@hotwired/stimulus';
import { MDCIconButtonToggle } from '@material/icon-button';

export default class extends Controller {
  connect() {
    this.toggle = new MDCIconButtonToggle(this.element);
    this.toggle.listen('MDCIconButtonToggle:change', (event) => {
      this.dispatchChange(event);
    });
    this.element.addEventListener('turnOn', () => {
      this.toggle.on = true;
    });
    this.element.addEventListener('turnOff', () => {
      this.toggle.on = false;
    });
  }

  disconnect() {
    if (this.toggle) {
      this.toggle.destroy();
    }
  }

  dispatchChange(event) {
    this.dispatch('change', { detail: { isOn: event.detail.isOn } });
  }
}
