import Rails from '@rails/ujs';
import { RDVimeoEventCapture } from 'raindrops';
import Player from '@vimeo/player';

const requiredCompletionPercent = 0.98;
let videoIds = [];
document.querySelectorAll('.vimeo-video').forEach(function (video) {
  videoIds.push(video.dataset.videoId);
});

RDVimeoEventCapture.initialize(Player, function (videoForm) {
  if (['play', 'pause', 'ended'].includes(eventKind(videoForm))) {
    Rails.fire(videoForm, 'submit');
  } else if (eventKind(videoForm) == 'timeupdate') {
    if (allVideosComplete(videoForm)) {
      enableNextNavigation();
    }
  }
});

function allVideosComplete(videoForm) {
  if (parseFloat(playbackPercent(videoForm)) >= requiredCompletionPercent) {
    videoIds = videoIds.filter((id) => id != videoId(videoForm));
  }

  return videoIds.length == 0;
}

function eventKind(videoForm) {
  return videoForm.querySelector("[name='video_action[kind]']").value;
}

function playbackPercent(videoForm) {
  return videoForm.querySelector(
    "[name='video_action[current_playback_percent]']"
  ).value;
}

function videoId(videoForm) {
  return videoForm.querySelector("[name='video_action[vimeo_id]']").value;
}

function enableNextNavigation() {
  let navigationDisabled = document.getElementById('video-disabled-navigation');
  if (navigationDisabled) {
    navigationDisabled.classList.add('display-none');
    document
      .getElementById('video-enabled-navigation')
      .classList.remove('display-none');
  }
  let videoMarker = document.querySelector(
    '[data-multiple-choice-question-video-complete-value]'
  );
  if (videoMarker) {
    videoMarker.dataset.multipleChoiceQuestionVideoCompleteValue = 'true';
  }
}
