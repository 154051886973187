import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';
import { RDDragAndDropList } from 'raindrops';

export default class extends Controller {
  connect() {
    new RDDragAndDropList(handleDrop);
  }
}

function handleDrop(dropOptions) {
  if (dropOptions.dragElement.id == dropOptions.droppedOnElement.id) {
    return;
  }

  let newListItems = document.createDocumentFragment();
  let listItems = document.querySelectorAll(
    "[draggable][data-sortable-list-item='true']"
  );
  let formData = new FormData();
  const idName = dropOptions.dragElement.dataset.listItemNamespace + '[id]';
  const typeName = dropOptions.dragElement.dataset.listItemNamespace + '[type]';
  listItems.forEach((itemElement) => {
    if (itemElement.innerHTML != dropOptions.dragElement.innerHTML) {
      if (itemElement.innerHTML == dropOptions.droppedOnElement.innerHTML) {
        newListItems.appendChild(dropOptions.dragElement.cloneNode(true));
        formData.append(idName, dropOptions.dragElement.dataset.listItemId);
        formData.append(typeName, dropOptions.dragElement.dataset.listItemType);
      }
      newListItems.appendChild(itemElement.cloneNode(true));
      formData.append(idName, itemElement.dataset.listItemId);
      formData.append(typeName, itemElement.dataset.listItemType);
    }
  });
  let tableBody = dropOptions.dragElement.parentElement;
  tableBody.innerHTML = null;
  tableBody.appendChild(newListItems);
  new RDDragAndDropList(handleDrop);
  Rails.ajax({
    url: listItems[0].dataset.endpointUrl,
    type: 'PUT',
    data: formData,
    dataType: 'json'
  });
}
