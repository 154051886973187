import { Controller } from '@hotwired/stimulus';
import { MDCRipple } from '@material/ripple';

export default class extends Controller {
  connect() {
    this.ripple = new MDCRipple(this.element);

    if (this.element.classList.contains('mdc-icon-button')) {
      this.ripple.unbounded = true;
    }
  }

  disconnect() {
    if (this.ripple) {
      this.ripple.destroy();
    }
  }
}
