import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['ratingField'];

  changeValue(event) {
    const changedValue = Number.parseInt(event.target.dataset.value);
    this.toggleStars(changedValue, event.detail.isOn);
    this.ratingFieldTarget.value = changedValue;
  }

  buttons() {
    return this.element.querySelectorAll('button');
  }

  toggleStars(changedValue, isOn) {
    this.buttons().forEach((button) => {
      const buttonValue = Number.parseInt(button.dataset.value);
      if (buttonValue <= changedValue) {
        button.dispatchEvent(new Event('turnOn'));
      } else if (buttonValue > changedValue) {
        button.dispatchEvent(new Event('turnOff'));
      }
    });
  }
}
