import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['feedback'];
  static values = {
    videoComplete: Boolean
  };

  connect() {
    this.optionSelected = false;
  }

  selectOption(event) {
    this.optionSelected = true;
    this.markOptionSelected(event.currentTarget);
    this.displayOptionFeedback(event.params.feedbackId);
    if (this.videoCompleteValue) {
      this.enableNextNavigation();
    }
  }

  markOptionSelected(element) {
    element.classList.add('selected');
  }

  displayOptionFeedback(feedbackId) {
    document.getElementById('feedback').classList.remove('incorrect');
    document.getElementById('feedback').classList.remove('correct');
    if (feedbackId != null) {
      let newFeedbackElement = document.getElementById(feedbackId);

      if (newFeedbackElement != null) {
        this.feedbackTarget.innerHTML = newFeedbackElement.innerHTML;
        if (newFeedbackElement.classList.contains('incorrect')) {
          document.getElementById('feedback').classList.add('incorrect');
        }
        if (newFeedbackElement.classList.contains('correct')) {
          document.getElementById('feedback').classList.add('correct');
        }
        document.getElementById('feedback').classList.remove('display-none');
      } else {
        document.getElementById('feedback').classList.add('display-none');
      }
    }
  }

  videoCompleteValueChanged() {
    if (this.optionSelected) {
      this.enableNextNavigation();
    }
  }

  enableNextNavigation() {
    document
      .getElementById('disabled-navigation')
      .classList.add('display-none');
    document
      .getElementById('enabled-navigation')
      .classList.remove('display-none');
  }
}
