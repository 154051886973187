import Rails from '@rails/ujs';

document.addEventListener('DOMContentLoaded', function (event) {
  if (document.getElementById('email-favorites-submit') !== null) {
    document
      .getElementById('email-favorites-submit')
      .addEventListener('click', function () {
        Rails.ajax({
          url: this.dataset.path,
          type: 'post'
        });
      });
  }
});
