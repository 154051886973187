import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button'];

  connect() {
    this.pressed = false;
  }

  press(event) {
    let pressedButton = event.currentTarget;
    pressedButton.querySelector('input').checked = true;
    this.buttonTargets.forEach((button) => {
      button.ariaPressed = false;
      button.classList.remove('mdc-button--unelevated');
      button.classList.add('mdc-button--outlined');
    });
    pressedButton.ariaPressed = true;
    pressedButton.classList.remove('mdc-button--outlined');
    pressedButton.classList.add('mdc-button--unelevated');
  }
}
