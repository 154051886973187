import Rails from '@rails/ujs';

let resourceList = document.querySelector('#resources-list');

if (resourceList) {
  let links = Array.from(document.querySelectorAll('#resources-list a'));

  links.forEach(function (link) {
    link.addEventListener('click', function () {
      createResourcePageClick(this.text, this.href);
    });
  });

  function createResourcePageClick(title, url) {
    let data = new FormData();
    data.append('title', title);
    data.append('url', url);
    Rails.ajax({
      url: resourceList.dataset.clicks_post_url,
      type: 'post',
      data: data
    });
  }
}
