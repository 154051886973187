import { Controller } from '@hotwired/stimulus';
import { MDCSlider } from '@material/slider';

export default class extends Controller {
  static values = {
    lower: String,
    upper: String
  };

  connect() {
    this.slider = new MDCSlider(this.element);
    let setLabel = (value) => {
      this.element.querySelector(
        '.mdc-slider__value-indicator-text'
      ).innerHTML = `${this.lowerValue} ${100 - value}%<br>
        ${this.upperValue} ${value}%`;
    };
    setLabel(50);
    this.slider.foundation.adapter.setValueIndicatorText = setLabel;
  }

  disconnect() {
    if (this.slider) {
      this.slider.destroy();
    }
  }
}
